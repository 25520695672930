function init() {
	// Handle project category select field redirection
	const projectCategoryNavigation = document.querySelector(
		".project-category-navigation"
	);

	if (projectCategoryNavigation) {
		const handleProjectCategoryNavigationChange =
			projectCategoryNavigationRedirect(projectCategoryNavigation);
	}
}

function projectCategoryNavigationRedirect(navigation) {
	const selectField = navigation.querySelector("select");
	console.log(selectField);
	selectField.addEventListener("change", () => {
		const url = selectField.value;

		if (url) {
			window.location = url;
		}

		return false;
	});
}

export default { init };
